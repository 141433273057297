import React from 'react'
import { Input, Button } from 'antd'
import './index.less'

const { Loader } = window
const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const IconFont = Loader.loadBaseComponent('IconFont')

class HeaderBox extends React.Component {
  render() {
    let { data = {}, handleText, description, handleOpenModal, rightCenterP, saveText, isEvent, handelAuthName } = this.props
    return (
      <>
        <div className="alarm-detail-header-epidemic">
          <div className="alarm-detail-right">
            <div className="header-bottom">
              <div className="buttons">
                <div className="detail-text">
                  <Input.TextArea
                    className="detail-input"
                    placeholder={'请输入警情备注，最大长度不超过200'}
                    maxLength={201}
                    rows={1}
                    key={data.id}
                    onChange={handleText}
                    value={description}
                  />
                  <AuthComponent actionName={handelAuthName}>
                    <span className="save" onClick={() => saveText()}>
                      {data.isHandle == 0 ? (
                        <>
                          <IconFont type="icon-S_Edit_Copy" /> 保存
                        </>
                      ) : (
                        <>
                          <IconFont type="icon-S_Edit_Copy" /> 编辑
                        </>
                      )}
                    </span>
                  </AuthComponent>
                </div>
                {data.isHandle == 0 ? (
                  <AuthComponent actionName={handelAuthName}>
                    <div className="detail_header_button">
                      <Button className="header_btn" onClick={() => handleOpenModal(0)}>
                        <IconFont type={'icon-S_Photo_ThumbEffective'} theme="outlined" />
                        未违规
                      </Button>
                      <Button className="header_btn" onClick={() => handleOpenModal(1)}>
                        <IconFont type={'icon-S_Photo_ThumbInvalid'} theme="outlined" />
                        已违规
                      </Button>
                    </div>
                  </AuthComponent>
                ) : (
                  <div className={`detailed_rotate ${data.isEffective == 0 ? 'rotate_no' : 'rotate_yes'} `} />
                )}
              </div>
            </div>
            <div className="right-center">{rightCenterP}</div>
          </div>
        </div>
      </>
    )
  }
}

export default HeaderBox
