import React from 'react'

import './index.less'

const IconFont = Loader.loadBaseComponent('IconFont')
const CaptureCard = Loader.loadBusinessComponent('Card', 'CaptureCard')

class KeyPonitRollingCard extends React.Component {
  render() {
    let { data = {}, changeDetailView, isActive } = this.props
    return (
      <CaptureCard
        className={`rolling-card ${isActive ? 'active-card' : ''}`}
        onClick={() => changeDetailView(data.id)}
        imgUrl={data.picPath}
        hoverScale={false}
        relativeIcon={''}
        captureTime={data.alarmTime}
        deviceName={data.cameraName}
        prevChildren={
          <div className="item">
            {data.isHandle == 0 ? (
              <i className="content-cir" />
            ) : (
              <IconFont
                className={data.isEffective === 1 ? 'iconYellow' : 'iconRed'}
                type={data.isEffective === 1 ? 'icon-S_Photo_ThumbInvalid' : 'icon-S_Photo_ThumbEffective'}
                theme="outlined"
              />
            )}
            <span className="content-p">{data.isHandle == 0 ? '未处理' : data.isEffective == 0 ? '未违规' : '已违规'}</span>
          </div>
        }
      />
    )
  }
}

export default KeyPonitRollingCard
