import React from 'react'
import HeaderBox from '../headerBox'
import moment from 'moment'
import { Row, Col } from 'antd'
import './index.less'

const { Loader } = window
const LabelValue = Loader.loadBaseComponent('LabelValue')

const eventHeader = ({ data = {}, saveText, handleText, description, handleOpenModal, handelAuthName }) => {
  return (
    <HeaderBox
      saveText={saveText}
      handleText={handleText}
      description={description}
      handleOpenModal={handleOpenModal}
      data={data}
      handelAuthName={handelAuthName}
      isEvent={true}
      rightCenterP={
        <Row>
          <Col span={12}>
            <LabelValue label="所属区域" value={data.placeName} />
            <LabelValue label="设备地址" value={data.address} />
            <LabelValue label="设备名称" value={data.cameraName} />
          </Col>
          <Col span={12}>
            <LabelValue label="所在任务" value={data.taskName} />
            <LabelValue label="触发时间" value={data && moment(+data.alarmTime).format('YYYY.MM.DD HH:mm:ss')} />
            {/* <LabelValue label="持续时间" value={moment(+data.duration).format('mm分ss秒')} /> */}
          </Col>
        </Row>
      }
    />
  )
}

const headers = new Map([['alarm', eventHeader]])

export default headers
