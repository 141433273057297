import React from 'react'
import { message } from 'antd'
import { inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import headers from './components/headers'
import RollingCard from './components/rollingCard'
import './index.less'

const PageDetails = Loader.loadBusinessComponent('PageDetails')
const NoData = Loader.loadBaseComponent('NoData')
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap')
const Loading = Loader.Loading
const HorizontalScrollLayout = Loader.loadBaseComponent('HorizontalScrollLayout')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')

@withRouter
@inject('tab')
class snapshotDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: {},
      oldData: undefined,
      detailList: [],
      searchData: {},
      description: '',
      handleVisible: false,
      type: -1 //0：未违规，1：已违规，-1：未处理
    }
  }
  async componentDidMount() {
    let { history } = this.props
    const { id } = Utils.queryFormat(history.location.search)
    let res = await window.LM_DB.get('parameter', id)
    const { list, searchData, isHistoryAlarm, alarmData } = res
    const result = await Service.alarmResult.getTaskAlarmById({ id: alarmData.id })
    this.setState({
      detailList: list,
      searchData,
      isHistoryAlarm,
      data: result.data,
      loading: false,
      description: alarmData.operationDetail
    })
  }

  // 更新地址栏url
  updatePage = async id => {
    const { tab } = this.props
    const { searchData, detailList, data } = this.state
    await window.LM_DB.add('parameter', { id: id.toString(), list: detailList, searchData, alarmData: data })
    tab.goPage({
      data: { id },
      action: 'replace',
      isUpdate: true,
      moduleName: 'epidemicMoniterDetail'
    })
  }

  changeDetailView = async id => {
    const { detailList } = this.state
    const index = detailList.findIndex(v => v.id === id)
    if (detailList[index]) {
      const result = await Service.alarmResult.getTaskAlarmById({ id: detailList[index].id })
      return this.setState({ data: result.data, description: result.data.operationDetail }, () => this.updatePage(id))
    }
  }

  handleText = e => {
    const value = e.target.value
    if (value.length > 200) {
      return message.info('最大长度不超过200')
    }
    this.setState({ description: value })
  }

  handleOpenModal = type => {
    this.setState({ type, handleVisible: true })
  }

  onModalCancel = () => {
    this.setState({ handleVisible: false })
  }

  saveText = async () => {
    let { data, description, isHistoryAlarm, detailList } = this.state
    if (description === data.description) {
      return
    }
    const logOption = {
      isHistoryAlarm,
      libType: 'noCapDetail'
    }
    const option = { id: data.id, operationDetail: description, isEffective: -2 }
    await Service.alarmResult.updateTaskAlarm(option, logOption)
    data = Object.assign({}, data, option)
    const listItemIndex = detailList.findIndex(v => v.id === data.id)
    detailList[listItemIndex] = data

    this.setState({ data: { ...data }, detailList: [...detailList] }, () => {
      this.changeDetailView(data.id)
    })
    message.success('保存备注成功')
  }

  handleOk = async () => {
    let { data, type, detailList, description, isHistoryAlarm } = this.state
    const option = { id: data.id, isEffective: type, operationDetail: description }
    const logOption = {
      isHistoryAlarm,
      libType: 'noCapDetail'
    }
    await Service.alarmResult.updateTaskAlarm(option, logOption)
    data.isEffective = type
    data.operationDetail = description
    data.isHandle = 1
    const listItemIndex = detailList.findIndex(v => v.id === data.id)
    detailList[listItemIndex] = data
    SocketEmitter.emit(SocketEmitter.eventName.resolveControlAlarm, { ...data })
    if (detailList[listItemIndex + 1]) {
      data = detailList[listItemIndex + 1]
    }

    this.setState({ data: { ...data }, detailList: [...detailList], handleVisible: false }, () => {
      if (detailList[listItemIndex + 1]) {
        this.changeDetailView(detailList[listItemIndex + 1].id)
      }
    })
  }

  render() {
    const { data = {}, type, handleVisible, description, oldData, loading, detailList = [] } = this.state
    if (loading) {
      return <Loading loading={loading} />
    }
    let dataIndex = detailList.findIndex(v => (oldData ? v.id === oldData.id : v.id === data.id)),
      preData = undefined,
      nextData = undefined
    if (dataIndex > 0) {
      preData = detailList[dataIndex - 1]
      nextData = detailList[dataIndex + 1]
    }
    if (dataIndex === 0 && detailList.length > 1) {
      nextData = detailList[dataIndex + 1]
    }
    let HeaderView = headers.get('alarm')
    const extJson = JSON.parse(data.extraInfo)
    return (
      <div className="no-cap-detail-wrapper">
        <div className="b-container">
          <div className="obj-snapshot-detail" style={{ width: '100%', height: '100%', minHeight: 400 }}>
            <HeaderView
              handelAuthName="epidemicMoniterHandle"
              data={data}
              saveText={this.saveText}
              handleText={this.handleText}
              description={description}
              handleOpenModal={this.handleOpenModal}
            />
            <div className="picture-container">
              {preData && (
                <div className="nav-l">
                  <PageDetails
                    classNameNode="detail-text"
                    pageType="pre"
                    imgUrl={preData.scenePath}
                    id={preData.id}
                    onChange={this.changeDetailView}
                  />
                </div>
              )}
              {nextData && (
                <div className="nav-r">
                  <PageDetails
                    classNameNode="detail-text"
                    pageType="next"
                    imgUrl={nextData.scenePath}
                    id={nextData.id}
                    onChange={this.changeDetailView}
                  />
                </div>
              )}
              <ImageMovieMap
                type="personAlarm"
                data={{
                  ...data,
                  sceneUrl: data.scenePath,
                  captureTime: data.alarmTime,
                  deviceName: data.cameraName,
                  cid: data.cameraId,
                  captureId: data.id,
                  faceRect: extJson.face_rect,
                  faceFeature: extJson.face_feature,
                  faceUrl: extJson.face_feature ? data.picPath : undefined,
                  bodyRect: extJson.body_rect,
                  bodyFeature: extJson.body_feature,
                  bodyUrl: extJson.body_feature ? data.picPath : undefined
                }}
                key={data.id}
                dataKey={'infoId'}
                getAid={this.getAid}
              />
            </div>
            {!!detailList && !!detailList.length && (
              <HorizontalScrollLayout
                size={6}
                data={detailList}
                currentIndex={dataIndex}
                className="key-ponit-horizont"
                renderItem={v => (
                  <RollingCard
                    key={v.id}
                    className="detail-box-item"
                    data={v}
                    changeDetailView={this.changeDetailView}
                    isActive={data.id === v.id ? true : false}
                  />
                )}
              />
            )}
          </div>
        </div>
        <ConfirmComponent
          title={`${+type === 0 ? '未' : '已'}违规提醒确认`}
          visible={handleVisible}
          onCancel={this.onModalCancel}
          onOk={this.handleOk}
          width={320}
          icon={+type === 1 ? 'icon-S_Photo_ThumbInvalid' : 'icon-S_Photo_ThumbEffective'}
        >
          <div>{`点击“确定”将其标注为${+type === 0 ? '未' : '已'}违规？`}</div>
        </ConfirmComponent>
      </div>
    )
  }
}
export default snapshotDetail
